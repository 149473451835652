import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core'
import { NgFor, NgClass } from '@angular/common'
import { Title } from '@angular/platform-browser'
import { UrlService } from '@appShared/services/url.service'
import { AchievementsService } from '@appShared/services/achievements.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { MentorBadgesComponent } from '@appContent/components/mentor/badges/badges.component'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgClass, MentorBadgesComponent]
})
export class ProgressComponent implements OnInit {
   private _achievementsService = inject(AchievementsService)
   private _hybridService = inject(HybridWebviewInteropService)
   private _titleService = inject(Title)
   private _urlService = inject(UrlService)

   contestImageUri = `${environment.publicCdn.uri}/${environment.publicCdn.badgeImagesFolder}/contest.png?=${(new Date()).getTime()}`
   contestUri = environment.resourceMenuUris.contest
   contentRoute = environment.routes.content
   contentRootUri = this.contentRoute.uri

   /* Achievements Signals */
   contactCourseAchievements = this._achievementsService.contactCourseAchievements
   contactMentorAchievements = this._achievementsService.contactMentorAchievements

   ngOnInit() {
      this._titleService.setTitle(`LEAD - ${this.contentRoute.progress.title}`)
      //they don't want header for now since page had a header
      //setTimeout(() => { this._urlService.setHeaderTitle(this.contentRoute.progress.title) })
      setTimeout(() => { this._urlService.setHeaderTitle('') })

      this._achievementsService.refreshContactAchievements()
   }

   externalNavigate(uri: string) {
      this._hybridService.externalNavigate(uri)
   }
}
