import { RegistrationProduct } from '../../src/app/shared/services/registrations.service';

export interface RouteModel {
   baseUri: string
   title: string
   uri: string
}

const routes = {
   content: {
      courseLibrary: {
         title: 'Courses',
         baseUri: 'courses',
         uri: 'courses'
      } as RouteModel,
      faq: {
         title: 'FAQ',
         baseUri: 'faq',
         uri: 'faq'
      } as RouteModel,
      meetTheMentors: {
         title: 'Meet The Mentors',
         baseUri: 'meet-the-mentors',
         uri: 'meet-the-mentors'
      } as RouteModel,
      progress: {
         title: 'Awards',
         baseUri: 'awards',
         uri: 'awards'
      } as RouteModel,
      subMenu: {
         title: 'Resources',
         baseUri: 'resources',
         uri: 'resources'
      } as RouteModel,
      videoLibrary: {
         title: 'Personalities',
         baseUri: 'personalities',
         uri: 'personalities'
      } as RouteModel,
      title: 'LEAD',
      baseUri: 'learn',
      uri: 'learn'
   },
   member: {
      account: {
         billing: {
            title: 'Billing',
            baseUri: 'billing',
            uri: 'billing'
         } as RouteModel,
         membership: {
            title: 'Membership',
            baseUri: 'membership',
            uri: 'membership'
         } as RouteModel,
         notifications: {
            title: 'Notifications',
            baseUri: 'notifications',
            uri: 'notifications'
         } as RouteModel,
         profile: {
            title: 'Profile',
            baseUri: 'profile',
            uri: 'profile'
         } as RouteModel,
         progress: {
            title: 'Awards',
            baseUri: 'awards',
            uri: 'awards'
         } as RouteModel,
         users: {
            title: 'Users',
            baseUri: 'users',
            uri: 'users'
         } as RouteModel,
         title: 'Account',
         baseUri: 'account',
         uri: 'account'
      },
      setPassword: {
         title: 'Set Password',
         baseUri: 'set-password',
         uri: 'set-password'
      } as RouteModel,
      siteNotifications: {
         title: 'Notifications',
         baseUri: 'site-notifications',
         uri: 'site-notifications'
      } as RouteModel,
      title: 'Member Access',
      baseUri: 'member',
      uri: 'member'
   },
   registration: {
      payment: {
         title: 'Payment',
         baseUri: 'payment',
         uri: 'payment'
      } as RouteModel,
      complete: {
         title: 'Complete',
         baseUri: 'complete',
         uri: 'complete'
      } as RouteModel,
      confirmation: {
         title: 'Confirmation',
         baseUri: 'confirmation',
         uri: 'confirmation'
      } as RouteModel,
      seat: {
         title: 'Seat',
         baseUri: 'seat',
         uri: 'seat'
      } as RouteModel,
      title: 'Registration',
      baseUri: 'registration',
      uri: 'registration'
   },
   registrationLanding: {
      title: 'registration',
      baseUri: 'registration-landing',
      uri: 'registration-landing'
   },
   signUp: {
      title: 'Create Account',
      baseUri: 'sign-up',
      uri: 'sign-up'
   },
   title: 'LEAD',
   uri: ''
}

export const environment = {
   production: false,
   appStore: {
      apple: 'https://apps.apple.com/us/app/southwestern-lead/id6478800540 ',
      googlePlay: 'https://play.google.com/store/apps/details?id=com.southwestern.lead'
   },
   baseUri: 'https://southwestern-learning-web-portal-test-as.azurewebsites.net',
   brightcove: {
      accountId: 753829919001,
      experiences: {
         dashboard: '65e8c10567acf13ae75b3dfd',
         faq: '661436a3fd8b4685703d56bf',
         meetTheMentorsUrl: 'https://site-28869320.bcvp0rtal.com/',
         personalitiesPortalUrl: 'https://site-76420120.bcvp0rtal.com'
      },
      playerId: 'KrH874wOd'
   },
   dealerApiUri: 'https://testportal.advantage4dealers.com/api/dealer',
   playerUri: 'player',
   publicCdn: {
      uri: 'https://swfclearningprod.blob.core.windows.net/lead',
      dashboardImagesFolder: 'dashboard-images',
      moduleCoverImagesFolder: 'module-cover-images',
      resourceImagesFolder: 'resource-images',
      badgeImagesFolder: 'badge-images'
   },
   resourceMenuUris: {
      academicResources: 'https://www.advantage4teens.com',
      contest: 'https://sites.google.com/view/southwesternlead',
      privacyPolicy: 'https://southwesternadvantage.com/privacy-policy/'
   },
   routes,
   userPrincipalIdHeaderName: 'UserPrincipalId-29239E59E0924FC4',
   oAuthClientIdHeaderName: 'Client-Id-29239E59E0924FC4',
   oAuthClientSecretHeaderName: 'Client-Secret-29239E59E0924FC4',
   oAuthClientIdentifier: '9922177285349731',
   oAuthClientSecret: 'feaaec1a3f79435e82ae7bf712299850',
   serviceLayerClientIdHeaderName: 'Client-Id-EE8289B486034E60',
   serviceLayerClientId: '1204683634232208',
   serviceLayerClientSecretHeaderName: 'Client-Secret-EE8289B486034E60',
   serviceLayerClientSecret: 'e91ee2fd0f634a28aa43e0223c3864bf',
   registrationProducts: [
      {
         productSourceId: '' /* ALL Sites */,
         showLandingIfSingle: false,
         registrationSites: [
            {
               uri:
                  'https://test.advantage4kids.com/registration/posconfirmation',
               uriSuffix: '?token=',
               appendToken: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/advantage4kids.png',
               logoAlt: 'Advantage4Kids',
               description:
                  'Advantage4Kids explains math and reading concepts through story-based videos, tests knowledge with quizzes and games, and expands imaginations with National Geographic eBooks.',
               backgroundColor: 'rgba(145, 48, 141, 1)',
               color: '#fff'
            },
            {
               uri: 'https://test.skwids.com/registration/posconfirmation',
               uriSuffix: '?token=',
               appendToken: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/skwids.png',
               logoAlt: 'SKWIDS',
               description:
                  'Skwids helps kick-start learning through fun activities, videos, games, and quizzes that spark an early interest in science, social studies, math, and language arts.'
               //backgroundColor: 'rgba(0, 185, 244, 0.5)'
            },
            {
               uri:
                  'https://test.advantage4teens.com/registration/posconfirmation',
               uriSuffix: '?token=',
               appendToken: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/advantage4teens.png',
               logoAlt: 'Advantage4Teens',
               description:
                  'Advantage4Teens offers thousands of instructional videos, quizzes, worksheets, and articles to provide comprehensive learning and practice in all required subjects.',
               backgroundColor: 'rgba(2, 149, 265, 1)',
               color: '#fff'
            },
            {
               uri: '/registration',
               uriSuffix: '?token=',
               appendToken: true,
               appendSubscriptionId: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/LEADLogo-medium.png?v=20230320',
               logoAlt: 'LEAD',
               description:
                  'Life Skills & Leadership Development for Young People'
            }
         ]
      } as RegistrationProduct,
      {
         productSourceId: 'LEA' /* LEAD - Annual */,
         showLandingIfSingle: false,
         registrationSites: [
            {
               uri: '/registration',
               uriSuffix: '?token=',
               appendToken: true,
               appendSubscriptionId: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/LEADLogo-medium.png?v=20230320',
               logoAlt: 'LEAD',
               description:
                  'Life Skills & Leadership Development for Young People'
            }
         ]
      } as RegistrationProduct,
      {
         productSourceId: 'LEM' /* LEAD - Monthly */,
         showLandingIfSingle: false,
         registrationSites: [
            {
               uri: '/registration',
               uriSuffix: '?token=',
               appendToken: true,
               appendSubscriptionId: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/LEADLogo-medium.png?v=20230320',
               logoAlt: 'LEAD',
               description:
                  'Life Skills & Leadership Development for Young People'
            }
         ]
      } as RegistrationProduct,
      {
         productSourceId: 'SE5' /* Global Academy - Annual */,
         showLandingIfSingle: false,
         registrationSites: [
            {
               uri:
                  'https://test.advantage4kids.com/registration/posconfirmation',
               uriSuffix: '?token=',
               appendToken: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/advantage4kids.png',
               logoAlt: 'Advantage4Kids',
               description:
                  'Advantage4Kids explains math and reading concepts through story-based videos, tests knowledge with quizzes and games, and expands imaginations with National Geographic eBooks.',
               backgroundColor: 'rgba(145, 48, 141, 1)',
               color: '#fff'
            },
            {
               uri: 'https://test.skwids.com/registration/posconfirmation',
               uriSuffix: '?token=',
               appendToken: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/skwids.png',
               logoAlt: 'SKWIDS',
               description:
                  'Skwids helps kick-start learning through fun activities, videos, games, and quizzes that spark an early interest in science, social studies, math, and language arts.'
               //backgroundColor: 'rgba(0, 185, 244, 0.5)'
            },
            {
               uri:
                  'https://test.advantage4teens.com/registration/posconfirmation',
               uriSuffix: '?token=',
               appendToken: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/advantage4teens.png',
               logoAlt: 'Advantage4Teens',
               description:
                  'Advantage4Teens offers thousands of instructional videos, quizzes, worksheets, and articles to provide comprehensive learning and practice in all required subjects.',
               backgroundColor: 'rgba(2, 149, 265, 1)',
               color: '#fff'
            }
         ]
      } as RegistrationProduct,
      {
         productSourceId: 'SWA' /* Global Academy - Monthly */,
         showLandingIfSingle: false,
         registrationSites: [
            {
               uri:
                  'https://test.advantage4kids.com/registration/posconfirmation',
               uriSuffix: '?token=',
               appendToken: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/advantage4kids.png',
               logoAlt: 'Advantage4Kids',
               description:
                  'Advantage4Kids explains math and reading concepts through story-based videos, tests knowledge with quizzes and games, and expands imaginations with National Geographic eBooks.',
               backgroundColor: 'rgba(145, 48, 141, 1)',
               color: '#fff'
            },
            {
               uri: 'https://test.skwids.com/registration/posconfirmation',
               uriSuffix: '?token=',
               logoUri:
                  'https://test.southwesternlead.com/assets/images/skwids.png',
               logoAlt: 'SKWIDS',
               appendToken: true,
               //description: 'TODO - put back'
               description:
                  'Skwids helps kick-start learning through fun activities, videos, games, and quizzes that spark an early interest in science, social studies, math, and language arts.'
               //backgroundColor: 'rgba(0, 185, 244, 0.5)'
            },
            {
               uri:
                  'https://test.advantage4teens.com/registration/posconfirmation',
               uriSuffix: '?token=',
               appendToken: true,
               logoUri:
                  'https://test.southwesternlead.com/assets/images/advantage4teens.png',
               logoAlt: 'Advantage4Teens',
               description:
                  'Advantage4Teens offers thousands of instructional videos, quizzes, worksheets, and articles to provide comprehensive learning and practice in all required subjects.',
               backgroundColor: 'rgba(2, 149, 265, 1)',
               color: '#fff'
            }
         ]
      }
   ] as RegistrationProduct[]
};
