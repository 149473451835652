import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core'
import { NgIf } from '@angular/common'
import { CommonService } from '@appShared/services/common.service'

declare var $: any

@Component({
   // eslint-disable-next-line @angular-eslint/component-selector
   selector: 'app-simple-modal',
   template: `
    <div id="{{elementId}}" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ng-content></ng-content>
          </div>
          <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" *ngIf="showSave">Save changes</button>
        </div>
        </div>
      </div>
    </div>
  `,
   standalone: true,
   imports: [NgIf]
})
export class SimpleModalComponent implements AfterViewInit, OnDestroy {
   @Input() title: string
   @Input() elementId: string | number
   @Input() iFrameId: string | number
   @Input() showSave: boolean
   @Input() closeFunction: any

   $thisModal: any
   constructor(private _commonService: CommonService) { }

   ngAfterViewInit(): void {
      const elementId = this.elementId || new Date().getTime()

      this.$thisModal = $(`#${elementId}`)

      // if they give an elementId and pass a closeFunction
      // then attach to modal close event
      if (this.closeFunction) {
         const self = this
         this.$thisModal.on('hidden.bs.modal', (e) => {
            self._commonService.execute(self.closeFunction)
         })
      }
   }

   ngOnDestroy(): void {
      if (this.$thisModal) {
         this.$thisModal.modal('dispose')
      }
   }

   showModal(): void {
      if (this.$thisModal) {
         this.$thisModal.modal('show')
      }
   }

   hideModal(): void {
      if (this.$thisModal) {
         this.$thisModal.modal('hide')
      }
   }
}
