import { APP_INITIALIZER, ApplicationConfig } from '@angular/core'
import { TitleCasePipe } from '@angular/common'
import { provideRouter } from '@angular/router'
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http'
import { provideAnimations } from '@angular/platform-browser/animations'
import { APP_ROUTES } from './app.routes'
import { storeFreeze } from 'ngrx-store-freeze'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import {
   AppConfigProvider,
   HttpRequestInterceptor,
   HYBRID_WEBVIEW_TOKEN,
   JQUERY_TOKEN,
   MOMENT_TOKEN,
   Toastr,
   TOASTR_TOKEN
} from './shared/services'

/* eslint-disable @typescript-eslint/dot-notation */
const toastr: Toastr = window['toastr']
const moment = window['moment']
const jQuery = window['$']
const hybridWebView = window['HybridWebView']
/* eslint-enable @typescript-eslint/dot-notation */

/* extend the existing window interface to tell it about your new property. */
declare global {
   interface Window {
      sw_lead: any
   }

   interface Date {
      stdTimezoneOffset(): number

      isDstObserved(): boolean
   }
}

window.sw_lead = window.sw_lead || { api_history: [], errors: [] }

export const appConfig: ApplicationConfig = {
   providers: [
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      provideRouter(APP_ROUTES),
      {
         provide: APP_INITIALIZER,
         useFactory: (appConfigProvider: AppConfigProvider) => {
            return () => appConfigProvider.init()
         },
         multi: true,
         deps: [AppConfigProvider]
      },
      {
         provide: Window,
         useValue: window
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: HttpRequestInterceptor,
         multi: true
      },
      TitleCasePipe,
      //TODO - maybe "provide" these where they are actually used
      { provide: TOASTR_TOKEN, useValue: toastr },
      { provide: MOMENT_TOKEN, useValue: moment },
      { provide: JQUERY_TOKEN, useValue: jQuery },
      { provide: HYBRID_WEBVIEW_TOKEN, useValue: hybridWebView },
      provideEnvironmentNgxMask()
   ]
}

Date.prototype.stdTimezoneOffset = function (): number {
   const jan = new Date(this.getFullYear(), 0, 1)
   const jul = new Date(this.getFullYear(), 6, 1)
   return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}

Date.prototype.isDstObserved = function (): boolean {
   return this.getTimezoneOffset() < this.stdTimezoneOffset()
}
