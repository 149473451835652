import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, TrackByFunction } from '@angular/core'
import { NgIf, NgFor, NgClass } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, Observable, take } from 'rxjs'
import { toObservable } from '@angular/core/rxjs-interop'
import { AppFacade } from '@appShared/services/app.facade'
import { CommonService } from '@appShared/services/common.service'
import { ContainersService } from '@appContent/shared/services/containers.service'
import { ToastrType } from '@appShared/services/toastr.service'
import { IContainer } from '@appShared/interfaces/[Model-based]/container.interface'
import { IContentRegistration } from '@appShared/interfaces/[Model-based]/content-registration.interface'
import { Completion_ } from '@appShared/services/lookup/[CodeGen]/completion.domain'
import { ComponentType_ } from '@appShared/services/lookup/[CodeGen]/component-type.domain'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-container-list',
   templateUrl: './container-list.component.html',
   styleUrls: ['./container-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, NgClass]
})
export class ContainerListComponent {
   @Input() title: string
   @Input() areaId: number
   @Input() moduleName: string
   @Input() isDashboardContext: boolean
   @Input() completionTypeCode?: Completion_
   @Input() componentTypeCode?: ComponentType_
   // modules$: Observable<IContainer[]>
   modules: IContainer[] = []
   completionTypes = Completion_
   completedTokenModules: IContainer[] = []
   incompleteTokenModules: IContainer[] = []
   notStartedTokenModules: IContainer[] = []
   standardModules: IContainer[] = []
   isLoaded = false

   private _currentUser = inject(AppFacade).currentUser

   constructor(
      private _activatedRoute: ActivatedRoute,
      private _router: Router,
      private _commonService: CommonService,
      private _containersService: ContainersService,
      private _cd: ChangeDetectorRef
   ) {
      this.title = this.title || 'Module List'

      let resolveModules = (): Observable<IContainer[]> => {

         if (this.areaId) return this._containersService.getModules(this.areaId)

         const snapshot = this._activatedRoute.snapshot
         this.componentTypeCode = this.componentTypeCode || snapshot.data['componentTypeCode'] || null

         return this._containersService.getModulesForAreaComponent(this.componentTypeCode)
      }

      combineLatest([
         toObservable(this._currentUser),
         resolveModules()
      ])
         .pipe(take(1))
         .subscribe(([user, modules]) => {

            this._createModuleLinks(modules, user?.profile?.contact?.contentRegistrations)

            this.isLoaded = true

            this._cd.markForCheck()
         })
   }

   /*
   * private methods
   * */

   private _createModuleLinks(modules: IContainer[], contentRegistrations?: IContentRegistration[]) {


      /*TODO - remove below - testing  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
      //modules = [...modules, ...modules]
      //let insertModule = { ...modules[2], id: 7, "$id": '5' } as IContainer
      //modules.push(insertModule)
      /*TODO - remove above - testing  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/

      const moduleCoverImagesUri = `${environment.publicCdn.uri}/${environment.publicCdn.moduleCoverImagesFolder}`

      const statusImageBaseUri = `${moduleCoverImagesUri}/course-`
      const completedStatusImageUri = `${statusImageBaseUri}completed.png`
      const incompleteModulesUri = `${statusImageBaseUri}in-progress.png`
      const notStartedStatusImageUri = `${statusImageBaseUri}locked.png`

      modules.forEach((module: any) => {
         const moduleInfo = module.info
         const token = moduleInfo.encodedToken
         module.token = token

         if (token) {
            if (moduleInfo.isComplete) {
               module.statusImageUri = completedStatusImageUri
               this.completedTokenModules.push(module)
            }
            else if (moduleInfo.isLocked) {
               module.statusImageUri = notStartedStatusImageUri
               this.notStartedTokenModules.push(module)
            }
            else if (moduleInfo.isInProgress) {
               module.statusImageUri = incompleteModulesUri
               this.incompleteTokenModules.push(module)
            }
            else {
               module.statusImageUri = notStartedStatusImageUri
               this.notStartedTokenModules.push(module)
            }
         } else {
            module.standard = true
            this.standardModules.push(module)
         }

         if (!module.iconImageUri) {
            const hostedContentToken = module.info.hostedContentToken
            module.iconImageUri = hostedContentToken
               ? `${moduleCoverImagesUri}/${hostedContentToken}.png` :
               'https://via.placeholder.com/500x281' /*  TODO?? maybe different url if nothing there */
         }
      })

      //if no "incomplete" module, then pull first non-started
      if (!this.incompleteTokenModules.length && this.notStartedTokenModules.length) {
         let firstNotStartedModule = this.notStartedTokenModules[0]

         //  change statusImageUri label and locked property
         this.incompleteTokenModules.push({
            ...firstNotStartedModule,
            statusImageUri: incompleteModulesUri,
            info: {
               ...firstNotStartedModule.info,
               isLocked: false
            }
         } as any)

         //remove that first module from notStarted
         this.notStartedTokenModules.shift()
      }

      //limit view if wanting to view specific completionType
      if (this.completionTypeCode != null) {
         /* cast as "number" - Completion_.NotStarted is (0) and therefore falsey - can't switch on falsey **/
         switch (this.completionTypeCode as number) {
            case Completion_.Completed:
               this.incompleteTokenModules = []
               this.notStartedTokenModules = []
               this.standardModules = []
               break
            case Completion_.Incomplete:
               this.completedTokenModules = []
               this.notStartedTokenModules = []
               this.standardModules = []
               break
            case Completion_.NotStarted:
               this.completedTokenModules = []
               this.incompleteTokenModules = []
               this.standardModules = []
               break
         }
      }

      if (this.isDashboardContext) {
         //if in context of dashboard - we are only showing one card
         if (this.completedTokenModules.length) this.completedTokenModules = [this.completedTokenModules[0]]
         if (this.incompleteTokenModules.length) this.incompleteTokenModules = [this.incompleteTokenModules[0]]
         if (this.notStartedTokenModules.length) this.notStartedTokenModules = [this.notStartedTokenModules[0]]
         if (this.standardModules.length) this.standardModules = [this.standardModules[0]]
      }

      // add attribute to array for ng-switch in markup to handle section titles
      this.completedTokenModules['progressCompletionCode'] = Completion_.Completed
      this.incompleteTokenModules['progressCompletionCode'] = Completion_.Incomplete
      this.notStartedTokenModules['progressCompletionCode'] = Completion_.NotStarted;

      this.modules = [
         ...this.incompleteTokenModules,
         ...this.notStartedTokenModules,
         ...this.completedTokenModules,
         ...this.standardModules]

   }

   /*
  * public methods
  * */

   trackByModuleId: TrackByFunction<IContainer> = (index, module) => module.id

   goToRoute(module: IContainer) {
      if (!module.info.isLocked) {
         const token = module.info.encodedToken
         const queryParams = token ? { queryParams: { token } } : {}
         const route = token
            ? `/${environment.playerUri}`
            : `/${environment.routes.content.uri}/${this.areaId}/${module.id}/c/${module.urlSlug || 'module'}`

         this._router.navigate([`${route}`], queryParams)
      } else {
         this._commonService.messageUser('This course is currently locked', null, ToastrType.info)
      }
   }
}
