import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core'
import { catchError, combineLatestWith, Observable, of, Subject, switchMap, take, tap } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AuthService } from '@appShared/services/auth.service'
import { AccountsService } from '@appShared/services/accounts.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import {
   ISiteConfiguration,
   IUser
} from '@appShared/interfaces/[Interface-based]'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { ISubscription } from '@appShared/interfaces/[Model-based]/subscription.interface'
import { SubscriptionStatus_ } from './lookup/[CodeGen]/subscription-status.domain'
import { environment } from '@appEnvironments/environment'

export enum LoadingStatus {
   loading = 'loading',
   success = 'success',
   error = 'error'
}

//https://github.com/joshuamorony/signals-rxjs-demo/tree/main
export interface AppState {
   siteConfig: ISiteConfiguration | undefined
   currentUser: IUser | undefined,
   status: LoadingStatus
   error: string | null
}

@Injectable({
   providedIn: 'root'
})
export class AppFacade {
   private _authService = inject(AuthService)
   private _accountsService = inject(AccountsService)
   private _hybridWebviewInteropService = inject(HybridWebviewInteropService)

   private _appState = signal<AppState>({
      siteConfig: undefined,
      currentUser: undefined,
      status: LoadingStatus.loading,
      error: null
   })

   //selectors
   siteConfig = computed(() => this._appState().siteConfig)
   currentUser = computed(() => this._appState().currentUser)
   userHasValidSubscription = computed(() => {
      const subscriptions = this.currentUser()?.profile?.account?.subscriptions

      return subscriptions.find(sub => [
         SubscriptionStatus_.Active,
         SubscriptionStatus_.Paused,
         SubscriptionStatus_.OnHold,
         SubscriptionStatus_.Cancelled,
         SubscriptionStatus_.Ended].includes(sub.statusCode))
   })
   status = computed(() => this._appState().status)
   error = computed(() => this._appState().error)

   private _refreshCurrentUser = new Subject<IUser|undefined>()
   private refreshCurrentUser$ = this._refreshCurrentUser.asObservable()

   constructor(destroyRef: DestroyRef) {

      //reducers
      this.refreshCurrentUser$.pipe(
         tap((user) => this._integrateHybrid(user)),
         takeUntilDestroyed(destroyRef)
      ).subscribe({
         next: (currentUser) => {
            this._appState.update((state) => ({
               ...state,
               currentUser,
               status: LoadingStatus.success
            }))
         }
      })
   }

   /*
    private methods
   */

   private _prepareRouteUris(): Observable<boolean> {

      /* iterative method to loop over route objects
         to create usuable uris for ease of routing
         and no need to concatenate routes in code
      */

      let setConditionalUri = (parentUri?: any, uri?: any): string => {
         return parentUri
            ? uri
               ? `${parentUri}/${uri}`
               : parentUri
            : uri
               ? uri
               : ''
      }

      let setUrl = (obj: any, parentBaseUri?: any): void => {
         let baseUri = obj.baseUri

         Object.entries(obj)
            .forEach(([key, value]) => {
               if (typeof value === "object") {

                  // build "baseUri" to pass to children objects
                  baseUri = setConditionalUri(parentBaseUri, obj.baseUri)

                  setUrl(value, baseUri)

               } else if (key === 'uri') {

                  // set uri based on parent routes
                  obj[key] = setConditionalUri(parentBaseUri, value)
               }
            })
      }

      let routes = environment.routes

      setUrl(routes)

      return of(true)
   }

   private _integrateHybrid(user?: IUser): void {
      console.log('integrating user in hybrid service', user)
      if (!user) {
         this._hybridWebviewInteropService.clearUserInfo()
         return
      }

      const contact = user.profile?.contact
      if (contact) {
         this._hybridWebviewInteropService.integrateUserInfo(contact)
      } else {
         this._hybridWebviewInteropService.clearUserInfo()
      }
   }

   /*
    public methods
   */

   initializeSite(): Observable<boolean> {
      return this._authService.siteConfig$.pipe(
         combineLatestWith(this._accountsService.getCurrentUser()),
         combineLatestWith(this._prepareRouteUris())
      ).pipe(
         take(1),
         switchMap(([[siteConfig, currentUser], routesPrepared]) => {

            // override our environment base
            if (siteConfig.siteUri) {
               environment.baseUri = siteConfig.siteUri
            }

            this._appState.update((state) => ({
               ...state,
               siteConfig,
               currentUser,
               status: LoadingStatus.success
            }))

            this._refreshCurrentUser.next(currentUser)

            return of(true)
         }),
         catchError((err) => {
            this._appState.update((state) => ({
               ...state, status: LoadingStatus.error, error: err
            }))

            return of(false)
         })
      )
   }

   refreshCurrentUser(): Observable<IUser> {
      return this._accountsService.getCurrentUser().pipe(
         take(1),
         switchMap(user => {
            this._refreshCurrentUser.next(user)
            return of(user)
         })
      )
   }

   setUserSubscription(subscription: ISubscription): void {
      const user = this._appState().currentUser
      const profile = user?.profile || {} as IProfile
      const account = profile?.account

      const existingSubscriptions = account?.subscriptions || []
      const nonMatchingSubscription = existingSubscriptions.filter(sub => sub.id !== subscription.id)
      const subscriptions = [...nonMatchingSubscription, subscription]

      const currentUser = {
         ...user,
         profile: {
            ...profile,
            account: {
               ...account,
               subscriptions
            }
         }
      }

      this._appState.update((state) => ({
         ...state,
         currentUser,
         status: LoadingStatus.success
      }))
   }

   setUserMustResetPassword(mustResetPassword?: boolean): void {

      const user = this._appState().currentUser
      const currentUser = {
         ...user,
         mustResetPassword: mustResetPassword || false
      }

      this._appState.update((state) => ({
         ...state,
         currentUser,
         status: LoadingStatus.success
      }))
   }
}
